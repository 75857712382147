.App {
    text-align: center;
}

div:focus {
    outline:none;
}

body {
    color: #3C3C3C;
}

body.gs {
    overflow-x: hidden;
}

body.modal-open {
    overflow: hidden !important;
    padding: 0 !important;
}

.send-to-back {
    z-index: -1000;
}

.modal-backdrop {
    z-index: 10005;
}

.modal {
    z-index: 10010;
}

* {
    font-family: 'Poppins', sans-serif;
}

.r-0 {
    right: 0 !important;
}

.b-0 {
    bottom: 0 !important;
}

.w-30 {
    width: 30% !important;
}

.no-underline:hover {
    border-bottom: none !important;
}

.gs-sign-up input {
    width: 18rem;
    height: 2.5rem;
    display: inline-flex;
}

.gs-sign-up button {
    margin: 1rem;
    height: 2.5rem;
}

.btn, .gs-sign-up button {
    width: 8rem;
    border-radius: 10px;
    border: 2px solid #3C3C3C;
    background-color: #fff;
    color: #3C3C3C;
}

.btn:hover, .gs-sign-up button:hover {
    background-color: #3C3C3C;
    color: #fff;
}

.hover-underline:hover {
    text-decoration: underline;
}

.gs-content {
    padding-top: 5rem;
}

p.gs a {
    color: #3C3C3C;
}

img.theme-icon {
    padding-top: 5rem;
}

.gs-color {
    color: #3C3C3C;
}

.gs-break {
    height: 150px;
}

.gs-navbar-logo img {
    height: 10vh;
    max-height: 8rem;
}

.gs-navbar {
    width: 100vw;
    height: 10vh;
    min-height: 5rem;
    position: fixed;
    z-index: 10001;
    top: 0;
    background-color: transparent;
    -webkit-transition: top 0.2s cubic-bezier(0.1, 0.19, 0.2, 0.89), background-color 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: top 0.2s cubic-bezier(0.1, 0.19, 0.2, 0.89), background-color 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: top 0.2s cubic-bezier(0.1, 0.19, 0.2, 0.89), background-color 0.2s linear, box-shadow 0.2s linear;
    transition: top 0.2s cubic-bezier(0.1, 0.19, 0.2, 0.89), background-color 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.gs-dropdown-menu-mobile a {
    margin: 0;
    display: block;
    color: #3c3c3c;
    height: auto !important;
    font-size: 1.5rem !important;
}

.gs-dropdown-menu {
    display: none;
    position: absolute;
    top: 5rem;
    background-color: #fff;
    border-radius: 0 0 0.25rem 0.25rem;
    padding: 1rem 0 0.5rem 1.5rem;
    margin: -1.05rem 0 0 -2rem;
    width: auto;
}

.gs-dropdown-menu:hover {
    display: block;
}

.gs-dropdown-menu a {
    margin: 0;
    display: block;
    height: auto !important;
}

.gs-dropdown-btn {
    padding: 0;
    margin: 0 0 0.5rem 1rem;
    width: 2rem;
    border: none;
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
    font-size: 2rem;
}

.gs-dropdown-menu a:hover {
    border-bottom: none!important;
}

.gs-navbar .gs-dropdown:hover>.gs-dropdown-menu {
    display: block;
}

.gs-navbar.nav-bcg {
    background-color: #fff !important;
    -webkit-box-shadow: 0px 10px 30px -12px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 10px 30px -12px rgba(0, 0, 0, 0.7);
}

.gs-navbar.nav-hide {
    top: -15vh;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.gs-navbar .nav-item {
    font-size: 1.25rem;
    width: auto;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.gs-nav-links a {
    color: #3C3C3C;
    padding-bottom: 4px;
}

.gs-nav-links a:hover {
    color: #227AD3;
    border-bottom: 2px solid #227AD3 !important;
    text-decoration: none;
}

.gs-nav-links a.active {
    color: #227AD3;
    border-bottom: 2px solid #227AD3 !important;
}

.gs-navbar .gs-banner.banner-hide {
    display: none;
}

.gs-navbar .gs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: inherit;
}

.gs-navbar .gs .gs-badge {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: auto;
}

.gs-navbar .gs .gs-badge.badge-hide {
    display: none;
}

.gs-navbar .gs .gs-nav-links a {
    margin-right: 2.15rem;
    height: 100%;
    width: auto;
    border-bottom: 2px solid transparent;
}

.gs-navbar .gs .gs-nav-links .nav-item {
    margin-right: 2.5rem;
    height: 100%;
    width: auto;
}

.form-control {
    border-radius: 0;
}

.gs-navbar .gs .gs-nav-links #sponsor-button {
    padding: 10px 15px 10px 15px;
    background-color: #E64C4C;
    border-radius: 10px;
    color: #fff;
    margin-right: 0 !important;
}

.gs-navbar .gs .gs-nav-links #sponsor-button:hover {
    background-color: #EB7070;
    border-bottom: 2px solid transparent !important;
}

@media (min-width: 576px) {
    .w-sm-50 {
        width: 50% !important;
    }
}

@media (min-width: 992px) {
    .gs-navbar {
        background-color: #fff !important;
    }
}

@media (max-width: 1199px) {
    .gs-navbar .gs .gs-nav-links a {
        font-size: 0.95rem;
    }
    .gs-navbar .gs .gs-nav-links .nav-item {
        font-size: 1rem;
    }
    .gs-navbar-logo img {
        max-height: 4rem;
    }
    .gs-navbar .gs .gs-nav-links a {
        margin-right: 1.2rem !important;
    }
}

.gs-navbar .gs div.drop-down {
    position: relative;
}

@media (hover: hover) {
    .gs-navbar .gs div.drop-down a:hover+.gs-nav-sublinks {
        visibility: visible;
        opacity: 1;
        -webkit-animation: fly-in 0.5s ease;
        animation: fly-in 0.5s ease;
    }
}

.gs-navbar .gs div.drop-down>a {
    padding-bottom: 10px;
}

.gs-navbar .gs div.drop-down div.gs-nav-sublinks {
    position: absolute;
    left: -20px;
    padding: 20px;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    top: calc(1.25rem + 10px);
}

.gs-navbar .gs div.drop-down div.gs-nav-sublinks div {
    font-size: 1rem;
    border-bottom: solid 2px #ED6A5A;
    margin: 0 0 3px 0;
    display: block;
}

.gs-navbar .gs div.drop-down div.gs-nav-sublinks div:hover {
    border-bottom: solid 2px #3C3C3C;
}

.gs-navbar .gs div.drop-down div.gs-nav-sublinks:hover {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 992px) {
    .gs-navbar .gs div.drop-down div.gs-nav-sublinks {
        display: none;
    }

    .gs-navbar .gs .gs-nav-links {
        display: none;
    }

    .gs-navbar .gs div.gs-mobile-hamburger {
        display: block !important;
    }
}

@-webkit-keyframes fly-in {
    0% {
        -webkit-transform: translateX(-50%);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}

@keyframes fly-in {
    0% {
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }
}

.gs-navbar .gs div.gs-mobile-hamburger {
    display: none;
    margin-left: auto;
}

.gs-navbar .gs div.gs-mobile-hamburger .hamburger-inner,
.gs-navbar .gs div.gs-mobile-hamburger .hamburger-inner::before,
.gs-navbar .gs div.gs-mobile-hamburger .hamburger-inner::after {
    background-color: #3C3C3C;
}

.gs-navbar .gs div.gs-mobile-hamburger .hamburger {
    padding-right: 0;
}

.gs-navbar .gs div.gs-mobile-hamburger .hamburger:hover {
    opacity: 1;
}

.gs-mobile-nav {
    opacity: 0;
    position: fixed;
    left: -100vw;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10000;
    overflow: hidden;
    padding: 5vh 0 0 10vw;
    -webkit-transition: left 0.3s cubic-bezier(0, 0.995, 1, 1), opacity 0.3s linear;
    transition: left 0.3s cubic-bezier(0, 0.995, 1, 1), opacity 0.3s linear;
}

.gs-mobile-nav a {
    color: #000;
    font-size: 2rem;
    width: auto;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.gs-mobile-nav a.active {
    border-bottom: 2px solid #000;
}

.gs-mobile-nav a:hover {
    color: #3C3C3C;
    border-bottom: 2px solid #69CDCD;
}

.gs-mobile-nav .gs-nav-links {
    margin-top: 5vh;
    margin-bottom: 10vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    width: auto;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.gs-mobile-nav .gs-nav-links a {
    width: auto;
    margin-top: 1rem;
    -webkit-transform: translateX(-75px);
    transform: translateX(-75px);
    opacity: 0;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.gs-mobile-nav .gs-nav-links .show-nav-link {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.gs-mobile-nav.mobile-nav-show {
    opacity: 1;
    left: 0;
}

.gs-mobile-nav .gs-logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: auto;
    height: 8rem;
}

.footer-nav-links {
    display: block;
    width: auto;
    /* position: absolute;
    bottom: 5vh; */
}

.footer-nav-links a {
    height: 100%;
    width: auto;
}

.footer-nav-links .gs-social-media a {
    display: inline-block;
}

.footer-nav-links .gs-social-media img {
    height: 2rem;
    margin-right: 2rem;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
}

.footer-nav-links .gs-social-media img:hover {
    opacity: 0.6;
    border-bottom: #fff !important;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

.gs-menu .gs-social-media img {
    height: 2.5rem;
    margin-right: 1rem;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
}

div.gs-footer .gs-social-media a {
    display: inline-block;
}

div.gs-footer .gs-social-media img {
    height: 1.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
}

div.gs-footer .gs-footer-graphics img {
    margin-top: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    align-items: flex-end;
}

div.gs-footer .gs-footer-logo img {
    height: 18vh;
    min-height: 70px;
    padding-top: 20px;
}

div.gs-footer .mb-n3 {
    margin-bottom: -1.5rem !important;
}

@media (max-width: 768px) {
    div.gs-footer .gs-social-media img {
        height: 2rem;
    }
    .gs-menu .gs-social-media img {
        height: 2.5rem;
    }

    .footer-nav-links .gs-social-media img {
        height: 2rem;
    }
}

h5{
    font-size: 1.4rem;
}

div.gs-footer .gs-social-media img:hover {
    opacity: 0.6;
}

div.gs-footer {
    padding: 0;
    width: 100%;
}

div.gs-footer h4 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.gs-block {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.gs-logo {
    white-space: nowrap;
    height: 3.5rem;
    width: 15.75rem;
}

.gs-logo img {
    height: 100%
}

.bg-marker {
    background-repeat: no-repeat;
    background-size: 100vw;
    height: 4vh;
}

.bg-dark-red {
    background: #E64C4C;
    color: #fff;
}

.bg-red {
    background-color: #DF7260 !important;
    color: #fff;
}

.bg-pink {
    background-color: #FFD4C4 !important;
}

.bg-light-yellow {
    background-color: #FED36A !important;
}

.bg-yellow {
    background-color: #F1BC00 !important;
}

.bg-light-blue {
    background: #f6fcfc;
}

.bg-dark-blue {
    background-color: #227AD3 !important;
}

.bg-blue {
    background-color: #69CDCD !important;
}

.bg-grey {
    background-color: #F7F8F9 !important;
}

.bg-dark-grey {
    background-color: #3D3D3D !important; 
    color: #fff;
}

.text-light-blue {
    color: #d7ecfdff !important;
}

.text-blue {
    color: #69CDCD !important;
}

.text-dark-blue {
    color: #227AD3 !important;
}

.text-red {
    color: #E64C4C !important;
}


.text-aqua {
    color: #9AD6D6 !important;
}

.text-pink {
    color: #EC9783 !important;
}

.text-yellow{
    color: #FECB48 !important;
}

.text-black {
    color: #3C3C3C !important;
}

.gs-landing {
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top:6vh;
    padding-bottom:10vh;
    height: 80vh;
}

.gs-landing-margin {
    margin-top: 4rem !important;
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
}

.gs-home-landing {
    height: 90vh;
}

.gs-companies-logo {
    padding-top: 5vh;
}

.gs-circle-img {
    background-color: #fff;
    height: 7rem;
    width: 7rem;
    padding: 1rem;
}

.gs-circle-img-pill {
    padding: 20px;
    border-radius: 80px;
    color: #fff;
}

.gs-tabs {
    border: none;
    display: flex;
    justify-content: center;
}

.gs-tabs .nav-item {
    border:none;
    color: #3C3C3C;
}

.gs-tabs .nav-item:hover {
    border:none;
    color: #3C3C3C;
}

.gs-tabs .nav-item:active {
    outline: none;
}

.gs-tabs .active {
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    color: #3C3C3C;
    border-bottom: 2px solid #3C3C3C !important;
}

#gs-htg-banner {
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 10001;
}

#gs-htg-banner a {
    color: #fff !important;
    text-decoration: underline;
}
